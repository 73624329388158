.user-table {
  border-collapse: separate;
  border-spacing: 0;
  letter-spacing: 1px;
  overflow: hidden;
  width: 100%;
  .thead.pad{
    padding-right: 14px;
  }
  .color {
    color: #707070;
  }
  .head-color{
    color: rgba($color: #000000, $alpha: 0.87);
  }
  .head-cell {
    position: sticky;
    top: 0;
    z-index: 2;

    .cell-content {
      position: relative;
      border-bottom: 1px solid #707070;
      width: 100%;
      bottom: 0;
    }
  }

  .data-cell {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .cell-content {
      padding: 10px 4px;
      *{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }
      .req{
        position: relative;
        &::after{
          content: '*';
          color: #b00;
          position: absolute;
          top: -4px;
          left: 2px;
        }
      }
    }
    .link-btn{
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .link {
    color: #1177CC;
  }

  .cell-content {
    padding: 4px;
  }

  .child-row {
    margin-top: 6px;
  }

  .tbody {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 128px;
  }

  .click {
    cursor: pointer;
  }
  .no-data{
    color: #707070;
  }
}

@media only screen and (max-width: 599px){
  .tbody{
    overflow: scroll!important;;
  }
}
@media only screen and (min-width: 600px) and (max-width: 959px){
  .tbody{
    overflow: scroll!important;;
  }
}