.sso{
  padding: 4.1em 10.4em;
  &.internal{
    padding: 4.1em 0;
  }
  .title{
    color: #000;
  }
  .sso-form{
    margin-top: 24px;
    padding-left: 4rem;
    .input-group{
      margin-bottom: 24px;
    }
  }
  .edit-btn{
    margin-left: 4rem;
  }
}
.settings{
  overflow: hidden;
  padding: 1.3% 1.3% 2px 2.6%;
  .tble-cont{
    position: relative;
  }
  .user-table{
    margin-top: 0;
  }
  .title{
    position: relative;
    top: 16px;
    &.dis{
      top: 22px;
    }
  }
  .btn-create{
    margin: 0 6rem 0 0;
  }
}
.config{
  overflow: hidden auto;
  padding: 2.6%;
  .label{
    color: #073C3C;
  }
  .settings-form{
    padding: 2rem 0 0 0;
    .head{
      margin-bottom: 16px;
    }
    .sec{
      margin-left: 4rem;
      margin-bottom: 6rem;
    }
    .form-input{
      margin-left: 3rem;
      margin-top: 16px;
      .label{
        margin-right: 4rem;
      }
      .select-group{
        margin-bottom: 0;
      }
      .input-group{
        margin-bottom: 0;
        color: #254343;
        .input-container{
          height: 43px;
        }
      }
    }
    .update{
      margin-top: 3rem;
      padding: 9px 15px;
      height: 45px;
    }
  }
}