.pagination-tab {
  .tab-container {
    padding: 3rem 16px 0 1rem;
  }

  .tab-btn {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #FFF;
    color: #262626;
    margin: 0 8px;
  }

  .tab-btn.active {
    background-color: #238787;
    color: #FFF;
  }

  .tab-divider {
    height: 1px;
    border-bottom: 1px dashed #707070;
  }
}