button {
  outline: none;
  border: none;
  cursor: pointer;
}
.btn-text{
  text-transform: capitalize;
  font: inherit;
  font-size: 17px;
}
.link-btn {
  color: #1177CC;
  padding: 8px;
  background-color: transparent;
  span{
    font: inherit;
  }
  &.disabled {
    color: #707070;
    cursor: not-allowed;
  }
}
.primary-btn{
  i, .btn-text, .loading{
    color: #FFF;
  }
}