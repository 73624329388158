.navbar {
  position: relative;
  padding: 25px;
  .org-logo{
    width: 240px;
    height: 56px;
    object-fit: contain;
  }
  .company {
    // position: absolute;
    // right: 16px;
    // top: 16px;
  }
  .items {
    padding: 0  0 0 7rem;
    .item {
      margin-right: 55px;
      color: #254343;
      cursor: pointer;
      &.active{
        color: #1177CC;
      }
    }
  }
  .account-options {
    margin-left: 16px;
    .company {
      margin-left: 45px;
      max-width: 200px;
      max-height: 40px;
    }
  
    .option {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
.app-nav{
  .items{
    // overflow: auto hidden;
  }
}
.app-menu {
  padding: 8px 15px;
  min-width: 250px;

  .profile-row {
    align-items: flex-start;
  }

  .menu-options-row {
    padding: 10px 0 10px 8px;
    justify-content: space-between;
  }
  .menu-profile{
    list-style: none;
    padding: 4px 0 0 8px;
    .menu-email{
      color: #17c;
    }
  }
  .switch-to {
    align-items: flex-start;
    padding-top: 12px;
    color: #072C3C;

    .switch-btn {
      background-color: rgba($color: #238787, $alpha: 0.5);
      border-radius: 6px;
      padding: 8px 14px;
      margin-left: 16px;
      color: #072C3C;

      .logo {
        width: 40px;
        height: 40px;
        margin-bottom: 4px;
      }
    }
  }
}
.profile-menus{
  max-width: 360px;
  min-width: 200px;
  .btn-profile{
    padding: 6px 12px;
    margin-bottom: 4px;
  }
  .usename{
    margin-top: 4px;
  }
  .profile-signout{
    margin-top: 8px;
    .profile {
      color: #072C3C;
      cursor: pointer;
      text-decoration: none;
    }
  
    .logout {
      color: #FF3333;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .profile-img{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
  .menu-entry{
    height: 35px;
    padding: 6px 12px;
    text-decoration: none;
    &:nth-child(1){
      margin-bottom: 12px;
    }
    &.link{
      cursor: pointer;
    }
  }
  .mod-logo{
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
}