.org-map-scr{
  overflow: hidden;
  padding: 1.3% 1.3% 2px 2.6%;
  .title{
    color: #073C3C;
    margin: 0 0 24px 4px;
  }
  .search{
    max-width: 500px;
    width: 23%;
  }
  .btn-invite{
    margin-right: 32px;
  }
  .search-icon{
    color: #707070;
  }
  .search-row{
    margin-bottom: 24px;
  }
  .tbl-container{
    margin-bottom: 8px;
  }
  .collapse{
    max-height: 0;
    transition: max-height 0.75s ease-in-out;
    overflow: hidden;
  }
  .collapse.show{
    max-height: 520px;
  }
  .rt{
    transform:rotate(180deg);
    -webkit-transition: -webkit-transform .8s ease-in-out;
    -ms-transition: -ms-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
    &.show{
      transform:rotate(0deg);
    }
  }
  .tbl-org{
    padding-bottom: 2px;
  }
  .btn-map{
    width: 245px;
    padding-left: 0;
    .arrow,
    .btn-text{
      color: #000000;
    }
    .btn-text{
      text-transform: none;
    }
  }
}