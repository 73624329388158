.login-main {
  overflow: hidden;
  position: relative;

  .left {
    width: 56.73%;
    background-color: #FFF;
    position: relative;
  }

  .app-btn {
    line-height: 1.2;
    height: 40px;
    min-width: 77px;

    &:disabled {
      border: 1px solid #D9D9D9;
      background: #F5F5F5;
      color: rgba($color: #000000, $alpha: 0.25);
    }
  }
  .input-group .error{
    box-shadow: none;
    border: 1px solid #BB2222;
  }
  .otp-error{
    border: 1px solid #D9D9D9;
    background: #F5F5F5;
    border-radius: 2px;
    padding: 6px 16px;
    min-width: 123px;
  }
  .right {
    width: 43.26%;
    position: relative;

    .neu {
      position: absolute;
      right: 24px;
      bottom: 13px;
      background-color: #FFF;
      width: 201px;
      height: 42px;
      object-fit: contain;
    }
  }

  .oval {
    width: 271px;
    height: 401px;
  }

  .board {
    max-width: 290px;
    background-color: #FFF;
    padding: 14px 20px;
    line-height: 1.3;
    border-radius: 4%;
    margin-left: 48px;
    margin-bottom: 1px;
    .fea {
      margin-bottom: 0;
      padding-left: 24px;
    }
  }

  .login-box {
    top: 26vh;
    width: 46%;
    position: relative;

    .login-form {
      margin-top: 1rem;
      width: 100%;
    }
    .input::placeholder {
      /* modern browser */
      color: #868686;
      font-size: 1.8rem;
    }
    .login-form-input {
      min-height: 40px;
    }

    .login-pwd-input {
      width: 95%;
      margin-bottom: 0;
    }

    .input-group.disabled {
      background-color: #F1F1F1;

      * {
        cursor: not-allowed;
      }
    }
    .input-container{
      padding: 10px;
      cursor: pointer;
      .input{
        cursor: pointer;
      }
    }

    .otp {
      width: 138px;
      margin-bottom: 0;

      .input-container {
        height: 42px !important;

        input {
          text-align: center;
          -moz-appearance: textfield;
          ms-inner-appearance: none;
        }

        input::-webkit-inner-spin-button,
        input::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .btn-resend {
      padding-top: 4px;
      text-align: center;
    }

    .btn-forget {
      padding: 0px;
      text-align: right;
    }
    .btn-login{
      min-width: 114px;
    }
    .aud-logo {
      width: 75%;
      margin-bottom: 48px;
      object-fit: contain;
    }

    .input-group {
      .input-container {
        min-height: 40px;
        height: 40px;
      }
    }
  }

  .pass-hint {
    list-style: none;
    margin: 0 0 16px 0;
    padding-left: 12px;

    li {
      color: #707070;
      margin-bottom: 4px;

      &::before {
        content: "\2022";
        /* Add content: \2022 is the CSS Code/unicode for a bullet */
        font-size: 40px;
        margin-right: 8px;
        vertical-align: middle;
        line-height: 20px;
        color: #A7A7A7;
      }
    }
  }

  .tc-row {
    position: absolute;
    bottom: 32px;
    left: calc(100vw * 0.015);

    .divider {
      font-size: 14px;
      height: 20px;
      border-color: #707070;
      margin: 0 12px;
    }

    .copy-right {
      position: absolute;
      left: 0;

      .symb {
        object-fit: contain;
        width: 2%;
        height: 2%;
        min-width: 40px;
        min-height: 40px;
        max-width: 56px;
        max-height: 56px;
      }
    }

    .copy-right-text {
      margin-left: 4px;
    }
  }

  .login {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 4px;
    }
  }
  .blink_me{
    animation-duration: 1.5s;
  }
  .board-container{
    position: absolute;
    left: calc(56.73% - 166px);
    top: 28%;
  }
}
.login-disable-dialog{
  max-width: 440px !important;
  .heading{
    color: #BB2222;
    font-weight: bold !important;
  }
  .subheading{
    line-height: 24px;
  }
  .sub-title-cnt{
    margin-bottom: 0;
  }
  .action{
    align-items: flex-end;
    .btn-ok{
      width: 61px;
    }
  }
}
.forget-pass {
  .aud-logo{
    margin-bottom: 0 !important;
  }
  .login-box {
    top: 200px;
    left: unset;
    width: 400px;

    .subtitle {
      margin: 8px 0;
      color: #A7A7A7;
    }
  }
  .input::placeholder {
    /* modern browser */
    color: #868686;
    font-size: 1.6rem;
  }
  .login-form{
    margin-top: 36px !important;
  }
  .link-btn{
    padding: 0;
  }
  .head{
    text-align: center;
    margin-bottom: 8px;
  }
}

.profile-screen {
  padding: 32px;

  .deselect {
    color: #254343;
  }

  .tab {
    margin-left: 32px;
  }

  .pass-form {
    padding: 32px 0;

    .reset-btn {
      margin-left: 4rem;
    }
  }
}

.reset-page {
  .card {
    position: relative;
    padding: 24px 16px 24px 32px;
    width: 840px;
    height: 600px;
    background-color: #EDFEFF;
  }

  .form-sec {
    margin-top: 0px;
  }

  .oval {
    height: 440px;
    width: 320px;
    margin-bottom: auto;
  }

  .form {
    width: 70%;
    margin: 16px 0;

    .app-btn {
      margin: 24px 0;
      width: 150px;
    }
  }

  .input-container {
    border-color: #595959;
    background-color: #ffdddd;

    .input {
      text-align: center;
    }
  }

  .logo {
    position: absolute;
    bottom: 16px;
    right: 24px;
    max-width: 163px;
    height: 48px;
  }
}

.custom-width {
  max-width: none !important;
}
.cancel-icon {
  color: rgba(252, 251, 251, 0.7);
}