@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?sifyyl');
  src:  url('../../assets/fonts/icomoon.eot?sifyyl#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf?sifyyl') format('truetype'),
    url('../../assets/fonts/icomoon.woff?sifyyl') format('woff'),
    url('../../assets/fonts/icomoon.svg?sifyyl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-dropup:before {
  content: "\e900";
}
.icon-arrow-forward:before {
  content: "\e901";
}
.icon-back:before {
  content: "\e902";
}
.icon-chavron-down:before {
  content: "\e8f0";
}
.icon-checkmark:before {
  content: "\e904";
}
.icon-file-alt:before {
  content: "\e905";
}
.icon-plus:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-signout:before {
  content: "\e909";
}


