.module-check {
  cursor: pointer;
  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #707070;
    margin-right: 18px;
  }
  .checkbox.checked{
    background-color: #1177CC;
    border-color: #1177CC;
  }
  .check{
    color: #FFF;
  }
  .logo{
    width: auto;
    max-width: 200px;
    height: 48px;
    object-fit: cover;
  }
  &:not(:first-child):not(:last-child){
    padding: 32px 0;
  }
}