.confirm-dialog {
  padding: 24px;
  max-width: 500px !important;

  .heading {
    color: #238787;
    margin-bottom: 24px;
    font-weight: 600;
  }
  .sub-title-cnt{
    margin-bottom: 3rem;
  }
  .subheading {
    color: #072C3C;
    padding: 0;
    margin: 0;
    line-height: 1.5;
  }
  .action {
    // margin-top: 54px;
  }
  .btn-text{
    color: #FFF;
  }
  .col.action .btn-cancel{
    margin-left: 0;
    margin-top: 1rem;
  }
  .btn-cancel{
    margin-left: 24px;
  }
  .sec-pass{
    margin-right: 16px;
    .input {
      width: 100%;
      border: 1px solid #9F9F9F;
      padding: 8px;
      font-size: 12px;
      color: #9f9f9f;
      border-radius: 4px;
    }
  }
}
.custom-ok {
  display: flex;
  justify-content: flex-end;
}
.custom-height {
  height: 160px;
}
