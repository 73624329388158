.user-map-scr{
  overflow: hidden;
  padding: 1.3% 1.3% 1.3% 2.6%;
  .title{
    color: #073C3C;
    margin: 0 0 24px 4px;
  }
  .search{
    width: 25%;
    max-width: 500px;
  }
  .tbl-container{
    margin-bottom: 0;
  }
  .tbl-container:nth-child(1){
    margin-bottom: 16px;
  }
  .deactivate-row{
    .cell-content{
      width: 100%;
    }
  }
  .deactivate,
  .reject{
    color: #FF3333;
  }
  .accept{
    color: #00CC55;
  }
  .btn-invite-user{
    margin-right: 16px;
    margin-left: 16px;
  }
}
.usr-invite{
  .form{
    padding-left: 5rem;
  }
  .suc-card{
    margin: 16px 0;
    animation-iteration-count: 1;
  }
  .success{
    color: #00CC55;
  }
  .error{
    color: #FF3333;
  }
  .note{
    margin-top: 4px;
    color: #254343;
  }
}
.user-add{
  padding: 0 1.3% 1.3% 2.6%;
  .form{
    padding-top: 16px
  }
  .page-head{
    margin-bottom: 16px;
  }
}
#upload-user-form{
  max-width: 80%;
  width: 350px;
  margin-top: 24px;
  .submit-btn{
    width: 140px;
  }
  .template{
    text-align: right;
    margin-bottom: 8px;
  }
}