.page-not-found{
  padding: 6rem 5rem 3.3rem;
  position: relative;
  align-items: center;
  .oval-404{
    position: absolute;
    top: 30px;
    left: 49%;
    width: 45%;
    max-width: 854px;
    max-height: 563px;
    height: 50.9rem;
    object-fit: contain;
  }
  .oval-404-text{
    font-size: 46.9rem;
    line-height: 38rem;
    margin: 0;
    font-weight: 300;
  }
  .txt{
    color: #007373;
  }
  .clang{
    font-size: 8.5rem;
    font-weight: 600;
  }
  .txt-404{
    font-size: 4.8rem;
    font-weight: 500;
  }
  .txt-info{
    color: #073C3C;
    font-size: 3.6rem;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  .back-home{
    width: 180px;
    padding: 2px 0;
    span{
      margin: 0;
    }
  }
  .oval-container{
    height: 100%;
    position: relative;
  }
  .text-container{
    padding-top: 64px;
  }
  .term-container{
    position: absolute;
    bottom: 3.3rem;
    .text{
      color: #0033CC;
      text-transform: capitalize;
      font-weight: normal;
    }
  }
  .divider{
    font-size: 14px;
    height: 20px;
    border-color: #707070;
    margin: 0 4px;
  }
}